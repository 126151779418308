import S1_I1 from "images/calculator/stage-1_1.jpg";
import S1_I2 from "images/calculator/stage-1_2.jpg";
import S1_I3 from "images/calculator/stage-1_3.jpg";
import S2_I1 from "images/calculator/stage-2_1.jpg";
import S2_I2 from "images/calculator/stage-2_2.jpg";
import S2_I3 from "images/calculator/stage-2_3.jpg";
import S2_I4 from "images/calculator/stage-2_4.jpg";
import S2_I5 from "images/calculator/stage-2_5.jpg";
import S2_I6 from "images/calculator/stage-2_6.jpg";
import S2_I7 from "images/calculator/stage-2_7.jpg";
import S2_I8 from "images/calculator/stage-2_8.jpg";

const calculator_apartments = {
  stage_1: [
    {
      id: "304",
      name: "1",
      rooms: 2,
      area: 89.45,
      price: 1207575,
      image: S1_I1,
      rates: [
        {
          type: 8,
          value: 96606,
          ownership_stay: "10 dni",
        },
        {
          type: 7.5,
          value: 90568.13,
          ownership_stay: "19 dni",
        },
        {
          type: 7,
          value: 84530.25,
          ownership_stay: "28 dni",
        },
      ],
    },
    {
      id: "208",
      name: "2",
      rooms: 2,
      area: 51.34,
      price: 718760,
      image: S1_I2,
      rates: [
        {
          type: 8,
          value: 57500.8,
          ownership_stay: "10 dni",
        },
        {
          type: 7.5,
          value: 53907,
          ownership_stay: "19 dni",
        },
        {
          type: 7,
          value: 50313.2,
          ownership_stay: "28 dni",
        },
      ],
    },
    // {
    //   id: "109",
    //   name: "3",
    //   rooms: 2,
    //   area: 51.34,
    //   price: 677688,
    //   image: S1_I3,
    //   rates: [
    //     {
    //       type: 8,
    //       value: 54215.04,
    //       ownership_stay: "7 dni",
    //     },
    //     {
    //       type: 7.5,
    //       value: 50826.6,
    //       ownership_stay: "14 dni",
    //     },
    //     {
    //       type: 7,
    //       value: 47438.16,
    //       ownership_stay: "21 dni",
    //     },
    //   ],
    // },
  ],
  stage_2: [
    {
      id: "A008",
      name: "1",
      rooms: 2,
      area: 39.29,
      price: 741509.64,
      image: S2_I1,
      rates: [
        {
          type: 8,
          value: 59320.77,
          ownership_stay: "12 dni",
        },
        {
          type: 7.5,
          value: 55613.22,
          ownership_stay: "21 dni",
        },
        {
          type: 7,
          value: 51905.68,
          ownership_stay: "30 dni",
        },
      ],
    },
    {
      id: "B113",
      name: "2",
      rooms: 1,
      area: 26.21,
      price: 428588.54,
      image: S2_I2,
      rates: [
        {
          type: 8,
          value: 34287.08,
          ownership_stay: "12 dni",
        },
        {
          type: 7.5,
          value: 32144.14,
          ownership_stay: "21 dni",
        },
        {
          type: 7,
          value: 30001.2,
          ownership_stay: "30 dni",
        },
      ],
    },
    {
      id: "B115",
      name: "3",
      rooms: 1,
      area: 26.21,
      price: 428588.54,
      image: S2_I3,
      rates: [
        {
          type: 8,
          value: 34287.08,
          ownership_stay: "12 dni",
        },
        {
          type: 7.5,
          value: 32144.14,
          ownership_stay: "21 dni",
        },
        {
          type: 7,
          value: 30001.2,
          ownership_stay: "30 dni",
        },
      ],
    },
    {
      id: "C118",
      name: "4",
      rooms: 1,
      area: 26.21,
      price: 428588.54,
      image: S2_I4,
      rates: [
        {
          type: 8,
          value: 34287.08,
          ownership_stay: "12 dni",
        },
        {
          type: 7.5,
          value: 32144.14,
          ownership_stay: "21 dni",
        },
        {
          type: 7,
          value: 30001.2,
          ownership_stay: "30 dni",
        },
      ],
    },
    {
      id: "C120",
      name: "5",
      rooms: 1,
      area: 26.21,
      price: 420739.53,
      image: S2_I5,
      rates: [
        {
          type: 8,
          value: 33659.16,
          ownership_stay: "12 dni",
        },
        {
          type: 7.5,
          value: 31555.46,
          ownership_stay: "21 dni",
        },
        {
          type: 7,
          value: 29451.77,
          ownership_stay: "30 dni",
        },
      ],
    },
    {
      id: "B218",
      name: "6",
      rooms: 1,
      area: 26.21,
      price: 435138.42,
      image: S2_I6,
      rates: [
        {
          type: 8,
          value: 34811.07,
          ownership_stay: "12 dni",
        },
        {
          type: 7.5,
          value: 32635.38,
          ownership_stay: "21 dni",
        },
        {
          type: 7,
          value: 30459.69,
          ownership_stay: "30 dni",
        },
      ],
    },
    {
      id: "C213",
      name: "7",
      rooms: 1,
      area: 26.21,
      price: 435138.42,
      image: S2_I7,
      rates: [
        {
          type: 8,
          value: 34811.07,
          ownership_stay: "12 dni",
        },
        {
          type: 7.5,
          value: 32635.38,
          ownership_stay: "21 dni",
        },
        {
          type: 7,
          value: 30459.69,
          ownership_stay: "30 dni",
        },
      ],
    },
    {
      id: "C300",
      name: "8",
      rooms: 3,
      area: 54.73,
      price: 1086277.97,
      image: S2_I8,
      rates: [
        {
          type: 7.5,
          value: 81470.85,
          ownership_stay: "21 dni",
        },
        {
          type: 7,
          value: 76039.46,
          ownership_stay: "30 dni",
        },
      ],
    },
  ],
};

export default calculator_apartments;

import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import {
  Hero,
  AboutInvestment,
  BusinessModel,
  Facilities,
  Aquapark,
  Calculator,
  Apartments,
  Location,
  Foundations,
  BuyProcess,
  Gallery,
} from "page_components/home";

import scrollToElement from "utils/scrollToElement";

const Home = ({ location, data }) => {
  const { hash, pathname } = location;
  const [init_loading, setInitLoading] = useState(false);

  const seo = data?.homePage?.seo;

  useEffect(() => {
    setInitLoading(true);
  }, [hash]);

  useEffect(() => {
    if (!!!hash) return;

    if (!!init_loading) {
      scrollToElement(`${hash.split("?")[0]}-section`);
    } else {
      setTimeout(() => {
        scrollToElement(`${hash.split("?")[0]}-section`);
      }, 100);
    }

    setInitLoading(true);
  }, [location]);

  return (
    <Layout
      seo={{
        title: seo?.title,
        description: seo?.metaDesc || "",
      }}
      location={{ pathname, hash }}
      apartments={data?.allApartments?.nodes}
      isHome
    >
      <Hero />
      <AboutInvestment />
      <BusinessModel />
      <BuyProcess />
      <Calculator />
      <Apartments
        data={{
          stage_1: data?.allApartments?.nodes.filter(
            (node) => node.nieruchomosci.investmentName === "Kyriad Karkonosze"
          ),
          stage_2: data?.allApartments?.nodes.filter(
            (node) => node.nieruchomosci.investmentName === "Kyriad 2"
          ),
        }}
      />
      <Location />
      <Facilities />
      <Aquapark />
      <Gallery />
      <Foundations />
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query {
    homePage: wpPage(id: { eq: "cG9zdDoxMA==" }) {
      seo {
        title
        metaDesc
        schema {
          raw
        }
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
    allApartments: allWpNieruchomosc {
      nodes {
        id
        title
        nieruchomosci {
          investmentName
          nameBuilding
          floor
          area
          rooms
          statusId
          cardLink
          photoLink1
        }
      }
    }
  }
`;

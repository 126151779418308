const priceFormatted = (price) => {
  const price_formatted = price
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const price_with_currency = `${price_formatted} zł`;

  return price_with_currency;
};

export default priceFormatted;

import "./styles.scss";
import React, { useState } from "react";
import classnames from "classnames";

import Select from "components/Select";
import RadioButton from "components/RadioButton";
import SectionHeader from "components/SectionHeader";
import Button from "components/Button";

import priceFormatted from "utils/priceFormatted";
import calculator_data from "libs/calculator_apartments.js";

const mainClass = "home-calculator";

const Calculator = () => {
  const [stage, setStage] = useState(1);
  const [apartment_selected, setApartmentSelected] = useState({
    label: calculator_data?.[`stage_${stage}`]?.[0]?.name,
    value: calculator_data?.[`stage_${stage}`]?.[0]?.id,
  });
  const [rate_selected, setRateSelected] = useState(8);

  let selected_apartment = calculator_data?.[`stage_${stage}`]?.find(
    (item) => item?.id === apartment_selected?.value
  );

  const handleTabChange = (stage) => {
    setApartmentSelected({
      label: calculator_data?.[`stage_${stage}`]?.[0]?.name,
      value: calculator_data?.[`stage_${stage}`]?.[0]?.id,
    });
    setStage(stage);
  };

  let find_selected_rate = selected_apartment?.rates?.find(
    (item) => item?.type === rate_selected
  );

  if (!!!find_selected_rate) {
    find_selected_rate = selected_apartment?.rates?.[0];
    return setRateSelected(selected_apartment?.rates?.[0]?.type);
  }

  const info_items_data = [
    {
      label: "Ilość pokoi",
      value: selected_apartment?.rooms,
    },
    {
      label: "Powierzchnia",
      value: (
        <>
          {selected_apartment?.area} m<sup>2</sup>
        </>
      ),
    },
    {
      label: "Wartość netto",
      value: priceFormatted(selected_apartment?.price),
    },
    {
      label: (
        <>
          Pobyt właścicielski do<sup>1</sup>
        </>
      ),
      value: find_selected_rate?.ownership_stay,
    },
  ];

  const prices_items = [
    {
      label: (
        <>
          Twój roczny zysk netto<sup>2</sup>:
        </>
      ),
      value: priceFormatted(find_selected_rate?.value),
    },
    {
      label: (
        <>
          Twój kwartalny zysk netto<sup>2</sup>:
        </>
      ),
      value: priceFormatted(
        parseFloat(find_selected_rate?.value / 4).toFixed(2)
      ),
      variant: "white",
    },
  ];

  return (
    <section id="kalkulator-zyskow-section" className={mainClass}>
      <div className="container">
        <SectionHeader
          title="Kalkulator zysków"
        />
        <div className={`${mainClass}__content`}>
          <div className={`${mainClass}__content__info`}>
            <h4>Zobacz przykładowe apartamenty:</h4>
            <small>
              W cenie zakupują Państwo lokal z osobną księgą wieczystą,
              apartament wypełni wykończony dostosowany w wymogów grupy
              hotelowej Louvre, miejsce postojowe na terenie hotelu.
            </small>
            <div className={`${mainClass}__content__info__items`}>
              <div
                className={`${mainClass}__content__info__item ${mainClass}__content__info__item--tabs`}
              >
                <Button
                  variant="round"
                  active={stage === 1}
                  onClick={() => handleTabChange(1)}
                >
                  Kyriad Karkonosze
                </Button>
                <Button
                  variant="round"
                  active={stage === 2}
                  onClick={() => handleTabChange(2)}
                >
                  KaiZEN
                </Button>
              </div>
              <div className={`${mainClass}__content__info__item`}>
                <label>Apartament</label>
                <Select
                  selected={{
                    label: selected_apartment.name,
                    value: selected_apartment.value,
                  }}
                  options={calculator_data?.[`stage_${stage}`]?.map(
                    ({ id, name }) => ({
                      label: name,
                      value: id,
                    })
                  )}
                  onChange={setApartmentSelected}
                />
              </div>
              <div className={`${mainClass}__content__info__item`}>
                <label>Stopa zwrotu</label>
                <div className={`${mainClass}__content__info__item__group`}>
                  {selected_apartment?.rates?.map(({ type }, index) => (
                    <RadioButton
                      key={index}
                      label={`${type}%`}
                      value={type}
                      input_value={rate_selected}
                      onChange={() => setRateSelected(type)}
                    />
                  ))}
                </div>
              </div>
              {info_items_data?.map(({ label, value }, index) => (
                <div
                  key={index}
                  className={`${mainClass}__content__info__item`}
                >
                  <label>{label}</label>
                  <span>{value}</span>
                </div>
              ))}
            </div>
            <div className={`${mainClass}__content__info__prices`}>
              {prices_items?.map(({ label, value, variant }, index) => (
                <div
                  key={index}
                  className={classnames(
                    `${mainClass}__content__info__prices__item`,
                    {
                      [`${mainClass}__content__info__prices__item--${variant}`]:
                        !!variant,
                    }
                  )}
                >
                  <label>{label}</label>
                  <span>{value}</span>
                </div>
              ))}
            </div>
            {stage === 1 ? (
              <>
                <small>
                  <sup>1</sup>Stała stopa zwrotu: 8% - 3 dni pobytu w sezonie
                  wysokim, 7 dni pobytu poza sezonem wysokim; 7,5% - 5 dni
                  pobytu w sezonie wysokim, 14 dni pobytu poza sezonem wysokim;
                  7% - 7 dni pobytu w sezonie wysokim, 21 dni pobytu poza
                  sezonem wysokim.
                </small>
              </>
            ) : (
              <>
                <small>
                  <sup>1</sup>Stała stopa zwrotu: 8% - 5 dni pobytu w sezonie
                  wysokim, 7 dni pobytu poza sezonem wysokim; 7,5% - 7 dni
                  pobytu w sezonie wysokim, 14 dni pobytu poza sezonem wysokim;
                  7% - 9 dni pobytu w sezonie wysokim, 21 dni pobytu poza
                  sezonem wysokim.
                </small>
              </>
            )}
            <small>
              <sup>2</sup>Kalkulacje potencjalnego zarobku oraz korzyści i
              ryzyka związane z inwestycją i dzierżawą są przedstawiane w biurze
              sprzedaży i omawiane indywidualnie przed zawarciem umowy.
              Niniejsza informacja nie jest ofertą handlową w rozumieniu art. 66
              § 1 k.c.
            </small>
          </div>
          <div className={`${mainClass}__content__plan`}>
            <span>{apartment_selected?.label}</span>
            <img src={selected_apartment?.image} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calculator;
